import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import Home from './pages/home/Home';
import Templates from './pages/templates/Templates';
import Start from './pages/create/Start';
import Developers from './pages/developers/Developers';
import Contact from './pages/contact/Contact';
import Privacy from './pages/privacy/Privacy';
import Tools from './pages/tools/Tools';

// Articles
import FAQs from './pages/articles/faqs/FAQs';
import Employers from './pages/articles/employers/Employers';
import HowItWorks from './pages/articles/how-it-works/HowItWorks';
import { FormDataProvider } from './pages/create/components/FormDataContext';
import ResumeReader from './components/resume-reader/ResumeReader';
import FightingDiscrimination from './pages/articles/fighting-discrimination/FightingDiscrimination';

const Routes = () => {
    return(
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/start">
                <FormDataProvider>
                    <Start />
                </FormDataProvider>
            </Route>
            <Route path="/templates">
                <Templates />
            </Route>
            <Route path="/employers">
                <Employers />
            </Route>
            <Route path="/about">
                <HowItWorks />
            </Route>
            <Route path="/fighting-discrimination">
                <FightingDiscrimination />
            </Route>
            <Route path="/tools">
                <Tools />
            </Route>
            <Route path="/developers">
                <Developers />
            </Route>
            <Route path="/faqs">
                <FAQs />
            </Route>
            <Route path="/contact">
                <Contact />
            </Route>
            <Route path="/privacy">
                <Privacy />
            </Route>
            <Route path="/utils">
                <ResumeReader />
            </Route>
        </Switch>
    );
}

export default Routes;
