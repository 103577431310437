import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderHelmet } from 'components/helmet/helmet';
import styled from "styled-components";

const FAQsPage = styled.div`
  padding-bottom: 5em;
  
  h1 {
    text-align: center;
  }
  
  h2 {
    font-weight: normal;
  }
`

const FAQs = () => {
    return (
        <FAQsPage>
            <HeaderHelmet
                pageName="FAQs | OpenResume"
                ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
                ogTitle="OpenResume: The next generation resume"
                ogURL="https://openresume.info/"
            />

            <h1>Frequently Asked Questions</h1>

            <section>
                <h2>Can I use my OpenResume anywhere?</h2>
                <p>
                    OpenResume is a type of PDF file, which means you can use it with any application process.
                    You can also print it off, however if you want to apply to jobs with one-click applications,
                    you need to use the original file.
                </p>
            </section>

            <section>
                <h2>How do I edit my OpenResume?</h2>
                <p>
                    Go to the <Link to="/start">Start page</Link> and choose 'Edit your OpenResume'. Upload your OpenResume and then
                    edit it as you need.
                </p>
            </section>

            <section>
                <h2>How is my data processed / stored?</h2>
                <p>
                    When you create your OpenResume, the generated resume is stored on our systems for 24 hours before being automatically
                    deleted. At this time, OpenResume is "stateless", which means we do not store any personal information to a database.
                    In the future, we may store personal information, however only with prior consent and under GDPR guidelines.
                </p>
                <p>For more information, please visit our <Link to="/privacy">Privacy Policy</Link></p>
            </section>

            <section id="errors">
                <h2>When I edit my resume it says "this PDF file isn't an OpenResume"</h2>
                <p>
                    You can only edit resumes created on this site. You can tell if your resume is an OpenResume by looking for
                    the OpenResume logo on the bottom left corner.
                </p>
            </section>

            <section>
                <h2>How do I change template?</h2>
                <p>
                    Go to the <Link to="/start">Start page</Link> and choose 'Edit your OpenResume'. Upload your OpenResume and then
                    go past the details page. On the third section, you can choose the new template you want to create your new
                    OpenResume with.
                </p>
            </section>

            <section>
                <h2>How do I know an employer will accept an OpenResume?</h2>
                <p>
                    When you're applying to a job, some employers will allow you to apply with one click using your
                    OpenResume. If they don't, you can still use your OpenResume - it will look like a normal PDF
                    file to them
                </p>
            </section>

            <section>
                <h2>Is my personal data stored on the OpenResume site?</h2>
                <p>
                    When we create your OpenResume, the information you provide is kept on our systems for a few minutes
                    before being automatically deleted. This is essential for the creation process. No other personal
                    information is being stored. For more information visit our <Link to="/privacy">Privacy Policy</Link>.
                </p>
            </section>

            <section>
                <h2>I'm an employer, why should I accept OpenResumes?</h2>
                <p>
                    We have a <Link to="/employers">dedicated guide</Link> explaining the benefits employers see
                    when switching to OpenResume.
                </p>
            </section>

        </FAQsPage>

    )
}

export default FAQs;
