import styled from "styled-components";
import {Viewport} from "styles/viewport";

const resumeAspectRatio = 0.707;

export const DeckWrapper = styled.div`
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  
  @media screen and (max-width: ${Viewport.LARGE}) {
    width: 100%
  }
`

export const Deck = styled.div`
  height: 600px;
  width: 600px;
  position: relative;

  img:nth-child(1) {
    transform: translate(-50%, -50%) rotate(-10deg);
    z-index: 3;
  }

  img:nth-child(2) {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 2;
  }

  img:nth-child(3) {
    transform: translate(-50%, -50%) rotate(10deg);
    z-index: 1;
  }
  
  @media screen and (max-width: ${Viewport.LARGE}) {
    width: 100%;
    height: 500px;
    padding-top: ${resumeAspectRatio}%;
    transform: rotateZ(2deg);
    background-color: #eee;
    border-radius: 10px;
  }

  @media screen and (max-width: ${Viewport.MEDIUM}) {
    height: 350px;
  }

  @media screen and (max-width: ${Viewport.SMALL}) {
    height: 300px;
  }
`;

export const Card = styled.img`
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 30px #00000055;
  left: 50%;
  top: 50%;
  aspect-ratio: ${resumeAspectRatio};
  image-rendering: crisp-edges;
  transform-origin: center 120%;
  position: absolute;
  box-sizing: content-box;

  &:hover {
    z-index: 4 !important;
    outline: 3px solid #5444ca;
  }
`;