import styled from "styled-components";
import {Viewport} from "styles/viewport";

export const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${30 / 16}rem;

  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-template-areas: "image" "description";
    grid-template-columns: auto;
    margin-bottom: ${100 / 16}rem;
  }
`;

export const DescriptionWrapper = styled.div`
  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-area: description;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: ${Viewport.MEDIUM}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const DescriptionTitle = styled.h3`
  font-size: ${36 / 16}rem;
  margin-bottom: 1.5rem;
  color: #333;
`;

export const DescriptionText = styled.div`
  margin-bottom: 2rem;
  
  p {
    font-size: ${24 / 16}rem;
  }
`;

export const ImageContainer = styled.div`
  svg {
    max-height: 300px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-area: image;
  }
`;