import React, { useContext, useEffect, useState } from 'react';
import { FormDataContext } from '../../components/FormDataContext';
import {
    TemplateComingSoon,
    TemplatePreview,
    TemplatesGallery,
    TemplatesPage
} from "pages/create/steps/step-3/CreateResumeStep3.styles";
import {getTemplates} from "services/templates";
import {Template} from "typings/templates";

const CreateResumeStep3 = () => {
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [templates, setTemplates] = useState(null);
    const formDataContext = useContext(FormDataContext);

    useEffect(() => {
        getTemplates().then((result: any) => {
            setTemplates(result);
        });
    }, []);


    const setTemplate = (template: Template) => {
        if (!template.complete) {
            return;
        }
        setSelectedTemplate(template.template);
        const currentState = formDataContext.formData;
        formDataContext.setFormData({...currentState, chosenTemplate: template});
        formDataContext.setProgressionDisabled(false);
    }

    const handleKeyPress = (keyEvent: KeyboardEvent, template: Template) => {
        if (keyEvent.key === 'Enter') {
            setTemplate(template);
        }
    }

    const displayTemplates = (templatesToShow: any) => {
        return (
            <TemplatesGallery>
            { templatesToShow.data.map((template: any, index: number) => (
                <TemplatePreview className={`${selectedTemplate === template.template && "active"} ${!template.complete && "incomplete"}`}
                     onClick={() => {setTemplate(template)}}
                     onKeyPress={(event: any) => {handleKeyPress(event, template)}}
                     role="button"
                     aria-pressed={selectedTemplate === template.id}
                     tabIndex={0}
                     key={index}>
                    <h2>{template.name}</h2>
                    { template.complete ? '' : <TemplateComingSoon>Coming soon!</TemplateComingSoon> }
                    <img className="preview"
                         src={template.previewURL}
                         alt={`Preview for ${template.name}`} />
                </TemplatePreview>
            ))}
            </TemplatesGallery>
        );
    }

    return (
        <TemplatesPage>
            <h1>Choose a template</h1>
            { templates && displayTemplates(templates) }
        </TemplatesPage>
    );
}

export default CreateResumeStep3;
