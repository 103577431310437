import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import CodeOverlay from './code-overlay.png';
import { HeaderHelmet } from 'components/helmet/helmet';
import {
    CallToActionHint, CodeCorner,
    HowItWorksPage,
    ResumeImage,
    ResumePreview
} from "pages/articles/how-it-works/HowItWorks.styles";

const S3_BUCKET_PREVIEW = 'https://openresume-public.s3.eu-west-2.amazonaws.com/templates/previews';

const HowItWorks = () => {
    return (
        <HowItWorksPage>
            <HeaderHelmet
                pageName="About | OpenResume"
                ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
                ogTitle="OpenResume: The next generation resume"
                ogURL="https://openresume.info/"
            />
            <section>
                <h2>The challenge</h2>
                <p>
                    When you apply to jobs, you're likely to send out dozens of applications to different
                    companies. What's frustrating, is that you'll send them all the same resume, but it seems
                    that each of them have a different hiring process. You send them your resume, and they ask
                    you to fill out a form, basically repeating everything you've already said in the resume.
                </p>
                <p>
                    Given all the information is already in your resume, why do employers force you to repeat
                    yourself in another form?
                </p>
                <h2>Why hiring processes take so long</h2>
                <p>
                    The fundamental problem is that there isn't an easy way to scan a traditional resume. When employers
                    are hiring, they need to build a profile around you, so when you apply for a job, you're
                    basically creating an online profile for them. It would be amazing if this was possible to
                    do automatically, however it's hard.
                </p>
                <p>
                    In some cases, you're asked to repeat all the information which is already in your resume,
                    which could take up to half an hour depending on how much detail is needed. If you're applying
                    to 10 jobs a day, you could have lost 5 hours.
                </p>
                <h2>Why is OpenResume different?</h2>
                <p>
                    OpenResume is a scannable resume. It looks like any other, but under the surface, it's very
                    different. When you apply to a company which accepts OpenResume, all you have to do is upload your
                    OpenResume. The OpenResume is your entire application. Since it contains your email and contact
                    information, employers know how to contact you.
                </p>
                <p>
                    Your OpenResume is designed to be scannable, which means if a company allows you to apply, it takes
                    one click. Send your OpenResume, and you're done.
                </p>
                <CallToActionHint>
                    Ready to get started?
                </CallToActionHint>
                <Link to="/start" className="start">
                    <Button color="primary"
                            variant="contained"
                            className="action-button"
                            endIcon={<Icon>arrow_forward</Icon>}>
                        Create your OpenResume
                    </Button>
                </Link>
            </section>
            <section>
                <ResumePreview>
                    <ResumeImage src={`${S3_BUCKET_PREVIEW}/developer-cv.jpg`} />
                    <CodeCorner src={CodeOverlay} />
                </ResumePreview>
            </section>
        </HowItWorksPage>
    )
}

export default HowItWorks;
