import styled from "styled-components";
import {Icon} from "@material-ui/core";
import {Viewport} from "styles/viewport";


export const GenerationPage = styled.div`
  .MuiCircularProgress-colorPrimary {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 20px;
  }

  .MuiCircularProgress-colorPrimary {
    color: #6C63FF;
  }
`;

export const ErrorIcon = styled(Icon)`
  color: #fca311;
  font-size: 100px;
`;

export const ErrorHeading = styled.h4`
  margin-bottom: -10px;
`;

export const PreviewTitle = styled.h2`
  text-align: center;
  font-size: 18px;

  @media screen and (max-width: ${Viewport.LARGE}) {
    margin-top: -20px;
  }
`;

export const PreviewWrapper = styled.a`
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #EEE;
  width: 100%;

  .scroll-reminder {
    display: none;
  }

  @media screen and (max-width: ${Viewport.LARGE}) {
    max-width: 100%;
    overflow-x: scroll;
    box-shadow: inset 0 0 0 0 #ccc;
  }
`;

export const GeneratingResumeMessage = styled.div`
  margin-top: 60px;
  text-align: center;
`;

export const ResumeLoaded = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;

  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-template-columns: 1fr;
  }
`;

export const GenerateResumeLink = styled.a`
  display: block;
  width: fit-content;
  margin: 40px auto 40px;
`;

export const DownloadWrapper = styled.div`
  border: 1px solid #EEE;

  div {
    padding: 10px 5px 15px 5px;
  }
`;

export const EditActions = styled.div`
  border-bottom: 1px solid #EEE;
`;

export const DownloadAction = styled.div`
  border-bottom: 1px solid #EEE;
  padding-bottom: 0 !important;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ActionTitle = styled.h2`
  text-align: center;
  font-size: 16px;
`;