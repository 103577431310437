import React from 'react';
import { Button, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from "styled-components";

export interface WizardButtonsProps {
    activeStep: number;
    steps: number;
    nextStep: () => void;
    previousStep: () => void;
    nextDisabled: boolean;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #CCC;
  padding-top: 20px;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const WizardButtons = (props: WizardButtonsProps) => {
    let {activeStep, steps, nextStep, previousStep, nextDisabled} = props;

    return (
        <Wrapper>
            <div className="button-container">
                <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    startIcon={<Icon>arrow_backward</Icon>}
                    onClick={previousStep}>
                    Back
                </Button>
            </div>
            <ButtonWrapper>
                {activeStep === steps - 1 ? (
                    <Link to={nextDisabled ? "#" : "/"}>
                        <Button variant="contained"
                                endIcon={<Icon>check</Icon>}
                                disabled={nextDisabled}
                                color="primary"
                                onClick={nextStep}>
                            Finish
                        </Button>
                    </Link>
                ) : (
                    <Button variant="contained"
                            endIcon={<Icon>arrow_forward</Icon>}
                            disabled={nextDisabled}
                            color="primary"
                            onClick={nextStep}>
                        Next
                    </Button>
                )}

            </ButtonWrapper>
        </Wrapper>
    )
}

export default WizardButtons;
