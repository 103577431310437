import styled from "styled-components";

export const ResumeUploader = styled.div`
  .MuiAlert-root {
    margin-top: 20px;
  }

  a {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }

  .material-icons {
    color: #a5a4a1;
    font-size: 64px;
  }
`;

export const DropZone = styled.div`
  border: 1px solid #CCC;
  width: 100%;
  height: 300px;
  box-shadow: inset 0 0 9px 0 #ccc;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;