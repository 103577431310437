import React, { useState } from "react";
import * as pdfjs from "pdfjs-dist";
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/display/api';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Button } from '@material-ui/core';
import copy from 'clipboard-copy';
import {
    ButtonsWrapper,
    PreviewCanvas,
    PreviewCode,
    StyledResumeReader,
    UploadButton
} from "components/resume-reader/ResumeReader.styles";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ResumeReader = () => {
    const [openResume, setOpenResume] = useState("");

    // Open and read the PDF
    const onChange = (event: any) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.onload = function() {
            const typedArray = new Uint8Array(this.result as ArrayBufferLike);
            pdfjs.getDocument(typedArray).promise.then((pdf: PDFDocumentProxy) => {
                displayMetadata(pdf);
                displayPreview(pdf);
            });
        };
        fileReader.readAsArrayBuffer(file);
    }

    // Display the decoded metadata on the page
    const displayMetadata = (pdf: PDFDocumentProxy) => {
        pdf.getMetadata().then(({info}) => {
            const resumeMeta = (info as any).Custom.OpenResume;
            setOpenResume(atob(resumeMeta));
        });
    }

    // Display the first page of the PDF on a canvas
    const displayPreview = (pdf: PDFDocumentProxy) => {
        pdf.getPage(1).then((page: PDFPageProxy) => {
            const viewport = page.getViewport({scale: 1});

            const canvas = document.getElementById("preview") as HTMLCanvasElement;

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport
            };
            // @ts-ignore
            page.render(renderContext);
        });
    }

    const copyJSONString = () => {
        copy(JSON.stringify(JSON.parse(openResume), null, 4));
    }

    const copyEscapedJSONString = () => {
        copy(JSON.stringify(JSON.stringify(JSON.parse(openResume))));
    }

    return (
        <StyledResumeReader>
            <div>
                <Button variant="contained"
                        component="label">
                    Upload file
                    <UploadButton id="input"
                           type="file"
                           accept=".pdf"
                           hidden
                           onChange={onChange}
                    />
                </Button>
            </div>
            <PreviewCanvas id="preview" />
            { openResume && (
                <PreviewCode language="javascript" style={a11yDark}>
                    {openResume}
                </PreviewCode>
            )}
            { openResume && (
                <ButtonsWrapper>
                    <Button variant="outlined" onClick={() => copyJSONString()}>Copy JSON</Button>
                    <Button variant="outlined" onClick={() => copyEscapedJSONString()}>Copy escaped JSON</Button>
                </ButtonsWrapper>
            )}
        </StyledResumeReader>
    );


}

export default ResumeReader;
