import styled from "styled-components";
import {Link} from "react-router-dom";
import {Colours} from "styles/colors";

export const LogoContainer = styled.div`
  padding: 10px;
  max-width: 110px;
`;

export const LogoLink = styled(Link)`
  text-decoration: none;
  color: ${Colours.PRIMARY};

  .bar-left {
    border-left: 3px solid ${Colours.PRIMARY};
    padding-left: 5px;
  }

  span {
    font-family: "Montserrat", sans-serif;
    font-size: ${24 / 16}rem;
    line-height: 1.2;
    font-weight: bold;
    color: ${Colours.PRIMARY};
  }

  &.grayscale {
    span {
      color: #696969;
    }
    .bar-left {
      border-left: 3px solid #696969;
    }
  }

  &:hover {
    .bar-left {
      color: ${Colours.SECONDARY};
      border-left: 3px solid ${Colours.SECONDARY};

      span {
        color: ${Colours.SECONDARY};
      }
    }
  }
`;