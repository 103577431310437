import React, { useEffect, useState } from 'react';
import {Template} from "typings/templates";

export interface FormData {
    details?: any;
    chosenTemplate?: Template;
}

export interface FormDataProviderProps {
    children: any;
}

export const FormDataContext = React.createContext({
    formData: {},
    setFormData: (formData: FormData) => {},
    progressionDisabled: true,
    setProgressionDisabled: (disabled: boolean) => {}
})

export const FormDataProvider = (props: FormDataProviderProps) => {

    // Retrieve form data from the local storage if it is present
    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('createFormData') as string);

        if (storedData) {
            setFormData(storedData);
        }
    }, []);


    const setFormData = (formData: FormData) => {
        setState(prevState => ({...prevState, formData: formData}));
        localStorage.setItem('createFormData', JSON.stringify(formData));
    }

    const setProgressionDisabled = (disabled: boolean) => {
        setState(prevState => ({...prevState, progressionDisabled: disabled}));
    }

    const [state, setState] = useState({
        formData: {},
        setFormData: setFormData,
        progressionDisabled: true,
        setProgressionDisabled: setProgressionDisabled
    });

    return (
        <FormDataContext.Provider value={state}>
            { props.children }
        </FormDataContext.Provider>
    )
}
