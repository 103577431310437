import React, { useContext } from 'react';
import Schema from 'config/schema.json';
import CreateResumeUISchema from './CreateResumeUISchema';
import SchemaForm from 'components/schema-form/SchemaForm';
import { FormDataContext } from '../../components/FormDataContext';

const CreateResumeStep2 = () => {
    const formDataContext = useContext(FormDataContext);

    const setFormData = (details: any) => {
        const currentState = formDataContext.formData;
        formDataContext.setFormData({...currentState, details: details});
        formDataContext.setProgressionDisabled(false);
    }

    const persistData = (details: any) => {
        const currentState = formDataContext.formData;
        formDataContext.setFormData({...currentState, details: details});
    }

    return (
        <div className="details-page">
            <h1>Enter your details</h1>
            <SchemaForm schema={Schema}
                        uiSchema={CreateResumeUISchema}
                        onSubmit={setFormData}
                        onChange={persistData}
                        // @ts-ignore
                        formData={formDataContext.formData.details}/>
        </div>
    );
}

export default CreateResumeStep2;
