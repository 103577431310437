import PeriodFieldTemplate from '../../../../components/schema-form/period/PeriodFieldTemplate';
import MonthYearTemplate from '../../../../components/schema-form/period/MonthYearTemplate';
import StringArrayTemplate from '../../../../components/schema-form/stringArray/StringArrayTemplate';

const CreateResumeUISchema = {
  "version": {
    "ui:widget": "hidden"
  },
  "uuid": {
    "ui:widget": "hidden"
  },
  "personal": {
    "summary": {
      "ui:widget": "textarea"
    },
    "contact": {
      "email": {
        "ui:widget": "email"
      }
    }
  },
  "workExperience": {
    "items": {
      "description": {
        "ui:widget": "textarea"
      },
      "period": {
        "ui:ObjectFieldTemplate": PeriodFieldTemplate,
        "start": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        },
        "end": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        }
      },
      "skills": {
        "ui:ArrayFieldTemplate": StringArrayTemplate
      },
      "highlights": {
        "ui:ArrayFieldTemplate": StringArrayTemplate
      }
    }
  },
  "volunteerExperience": {
    "items": {
      "description": {
        "ui:widget": "textarea"
      },
      "period": {
        "ui:ObjectFieldTemplate": PeriodFieldTemplate,
        "start": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        },
        "end": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        }
      },
      "skills": {
        "ui:ArrayFieldTemplate": StringArrayTemplate
      }
    }
  },
  "education": {
    "items": {
      "description": {
        "ui:widget": "textarea"
      },
      "activities": {
        "ui:widget": "textarea"
      },
      "period": {
        "ui:ObjectFieldTemplate": PeriodFieldTemplate,
        "start": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        },
        "end": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        }
      }
    }
  },
  "projects": {
    "items": {
      "description": {
        "ui:widget": "textarea"
      },
      "period": {
        "ui:ObjectFieldTemplate": PeriodFieldTemplate,
        "start": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        },
        "end": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        }
      }
    }
  },
  "awards": {
    "items": {
      "description": {
        "ui:widget": "textarea"
      },
      "date": {
        "ui:ObjectFieldTemplate": MonthYearTemplate
      }
    }
  },
  "certifications": {
    "items": {
      "valid": {
        "ui:ObjectFieldTemplate": PeriodFieldTemplate,
        "issueDate": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        },
        "expiryDate": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        }
      }
    }
  },
  "patents": {
    "items": {
      "description": {
        "ui:widget": "textarea"
      },
      "issueDate": {
        "ui:ObjectFieldTemplate": MonthYearTemplate
      },
      "additionalInventors": {
        "ui:ArrayFieldTemplate": StringArrayTemplate
      }
    }
  },
  "publications": {
    "items": {
      "description": {
        "ui:widget": "textarea"
      },
      "date": {
        "ui:ObjectFieldTemplate": MonthYearTemplate
      },
      "additionalAuthors": {
        "ui:ArrayFieldTemplate": StringArrayTemplate
      }
    }
  },
  "organisations": {
    "items": {
      "description": {
        "ui:widget": "textarea"
      },
      "period": {
        "ui:ObjectFieldTemplate": PeriodFieldTemplate,
        "start": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        },
        "end": {
          "ui:ObjectFieldTemplate": MonthYearTemplate,
        }
      }
    }
  },
  "references": {
    "items": {
      "email": {
        "ui:widget": "email"
      }
    }
  },
  "interests": {
    "ui:ArrayFieldTemplate": StringArrayTemplate
  }
}

export default CreateResumeUISchema;

