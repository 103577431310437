import styled from "styled-components";
import {Colours} from "styles/colors";
import {Viewport} from "styles/viewport";
import {Link} from "react-router-dom";

export const StyledFooter = styled.footer`
  background-color: ${Colours.SHADE};
  border-top: 1px solid #ccc;
  width: 100%;

  display: grid;
  grid-template-areas:
    "logo navigation copyright";
  grid-template-columns: 150px auto 100px;

  @media screen and (max-width: ${Viewport.MEDIUM}) {
    height: auto;
    grid-template-areas:
      "navigation navigation"
      "logo       copyright";
    grid-template-columns: 150px auto;
  }
`;

export const LogoWrapper = styled.div`
  grid-area: logo;
`;

export const Navigation = styled.nav`
  grid-area: navigation;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: ${Viewport.MEDIUM}) {
    justify-content: center;
    height: 40px;
    border-bottom: 1px solid #ccc;
  }

  @media screen and (max-width: ${Viewport.SMALL}) {
    flex-direction: column;
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const Copyright = styled.div`
  grid-area: copyright;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${Viewport.LARGE}) {
    justify-content: flex-end;
    padding-right: 20px;
  }
`;

export const StyledLink = styled(Link)`
  margin-left: 15px;

  @media screen and (max-width: ${Viewport.LARGE}) {
    text-align: center;
  }
`;