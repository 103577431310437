import React from "react";
import {Card, Deck, DeckWrapper} from "components/resume-fan/ResumeFan.styles";

export interface ResumePreview {
  src: string;
  alt: string;
}

export interface ResumeFanProps {
  resumes: ResumePreview[];
}

export const ResumeFan = (props: ResumeFanProps) => {
  const {resumes} = props;
  return (
    <DeckWrapper>
      <Deck>
        {resumes.map(resume => (
          <Card
            key={resume.src}
            src={resume.src}
            alt={resume.alt}
          />
        ))}
      </Deck>
    </DeckWrapper>
  )
}