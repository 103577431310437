import {Link} from "react-router-dom";
import {Button, Icon} from "@material-ui/core";
import React from "react";
import {
  DescriptionText,
  DescriptionTitle,
  DescriptionWrapper,
  ImageContainer, RowWrapper
} from "components/feature-row/FeatureRow.styles";

interface FeatureRowProps {
  title?: string;
  description: React.ReactNode;
  ctaText?: string;
  ctaLink: string;
  hideCta?: boolean;
  image: React.ReactNode;
  reverse?: boolean;
}


export const FeatureRow = (props: FeatureRowProps) => {
  const {title, description, ctaText, ctaLink, hideCta, image, reverse} = props;

  const descriptionPart = (
    <DescriptionWrapper>
      {title && <DescriptionTitle>{title}</DescriptionTitle>}
      <DescriptionText>{description}</DescriptionText>
      {!hideCta && (
        <Link to={ctaLink}>
          <Button color="primary"
                  variant="outlined"
                  endIcon={<Icon>arrow_forward</Icon>}>
            {ctaText}
          </Button>
        </Link>
      )}
    </DescriptionWrapper>
  );

  const imagePart = (
    <ImageContainer>
      {image}
    </ImageContainer>
  );

  return (
    <RowWrapper>
      {!reverse ? (
        <>
          {descriptionPart}
          {imagePart}
        </>
      ) : (
        <>
          {imagePart}
          {descriptionPart}
        </>
      )}
    </RowWrapper>
  );
}