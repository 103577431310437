import React from 'react';
import {Icon} from '@material-ui/core';
import {ResumeFan, ResumePreview} from "components/resume-fan/ResumeFan";
import {CreateResumeButton} from "components/create-resume-button/CreateResumeButton";
import {FeatureRow} from "components/feature-row/FeatureRow";
import {HeaderHelmet} from 'components/helmet/helmet';

import {ReactComponent as SocialBioSVG} from 'pages/home/resources/social_bio.svg';
import {ReactComponent as ChooseTemplateSVG} from 'pages/home/resources/choose_template.svg';
import {ReactComponent as QuickApplySVG} from 'pages/home/resources/quick_apply.svg';
import {ReactComponent as FastApplicationSVG} from 'pages/home/resources/fast_application.svg';
import {ReactComponent as SuperheroSVG} from 'pages/home/resources/superhero.svg';
import {ReactComponent as AnonymousSVG} from 'pages/home/resources/anonymous.svg';
import {ReactComponent as ResumeSVG} from 'pages/home/resources/resume.svg';

import AwesomeResume from 'pages/home/resources/awesome-resume.jpg';
import DeedyResume from 'pages/home/resources/deedy-resume.jpg';
import DeveloperCV from 'pages/home/resources/developer-cv.jpg';
import {
  Arrow, BenefitsSection, Centered, Count, CTA, HomePageWrapper, LandingContainer,
  SectionHeader, Stage, StepsContainer, StepsSection, VerticalWrapper
} from "pages/home/Home.styles";

const IntroDescription = () => (
  <>
    <p>
      OpenResume is the resume designed for the digital age - it can be read by humans and computers.
      Computers often make mistakes while reading resumes, which means you have to fill out details twice
      when applying to jobs.
    </p>
    <p>
      By using an OpenResume, you can drastically reduce the frustration and time it takes to apply
      to jobs.
    </p>
  </>
);

const LightningFastDescription = () => (
  <p>
    All the information employers need is contained in your OpenResume.
    This means you have less to fill out when applying.
  </p>
);

const TemplatesDescription = () => (
  <p>
    Our collection of resume templates is sure to make your application
    stand out. We handle all the formatting, and you can change templates whenever
    you like.
  </p>
);

const DiscriminationDescription = () => (
  <p>
    Employers using OpenResume can choose to hide personally identifying
    information, a proven method of reducing bias around race, gender identity, age
    and other protected attributes.
  </p>
);

const Home = () => {
  const resumesToDisplay: ResumePreview[] = [
    {
      src: DeveloperCV,
      alt: "Preview for the 'DeveloperCV' resume template"
    },
    {
      src: AwesomeResume,
      alt: "Preview for the 'AwesomeResume' resume template"
    },
    {
      src: DeedyResume,
      alt: "Preview for the 'DeedyResume' resume template"
    }
  ]

  return (
    <HomePageWrapper>

      <HeaderHelmet
        pageName="Home | OpenResume"
        ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
        ogTitle="OpenResume: The next generation resume"
        ogURL="https://openresume.info/"
      />

      <LandingContainer>
        <CTA>
          <VerticalWrapper>
            <h1>The next generation of resumes</h1>
            <h2>Never fill out a job application again</h2>
            <Centered><CreateResumeButton/></Centered>
          </VerticalWrapper>
        </CTA>
        <ResumeFan resumes={resumesToDisplay}/>

      </LandingContainer>

      <section>
        <SectionHeader>What is OpenResume?</SectionHeader>
        <FeatureRow hideCta ctaLink={""} image={<ResumeSVG/>} description={<IntroDescription />}/>
      </section>

      <StepsSection>
        <SectionHeader>Three easy steps</SectionHeader>
        <StepsContainer>
          <Stage>
            <Count>1</Count> <SocialBioSVG/> <h3>Fill out your profile</h3>
          </Stage>

          <Arrow>
            <Icon>arrow_forward</Icon>
          </Arrow>

          <Stage>
            <Count>2</Count> <ChooseTemplateSVG/> <h3>Choose a professional template</h3>
          </Stage>

          <Arrow>
            <Icon>arrow_forward</Icon>
          </Arrow>

          <Stage>
            <Count>3</Count> <QuickApplySVG/> <h3>Instantly apply to jobs</h3>
          </Stage>
        </StepsContainer>
        <Centered><CreateResumeButton/></Centered>
      </StepsSection>

      <BenefitsSection>
        <SectionHeader>Why use OpenResume</SectionHeader>
        <FeatureRow title="Lightning-fast job applications"
                    description={<LightningFastDescription />}
                    ctaText="See how it works"
                    ctaLink="/about"
                    image={<FastApplicationSVG/>}
        />
        <FeatureRow title="Stand out with expert templates"
                    description={<TemplatesDescription />}
                    ctaText="Browse the templates"
                    ctaLink="/templates"
                    reverse
                    hideCta
                    image={<SuperheroSVG/>}
        />
        <FeatureRow title="Reduce discrimination"
                    description={<DiscriminationDescription/>}
                    ctaText="See how we're fighting discrimination"
                    ctaLink="/fighting-discrimination"
                    image={<AnonymousSVG/>}/>
      </BenefitsSection>

      <Centered><CreateResumeButton/></Centered>
    </HomePageWrapper>
  )
}

export default Home;
