import React from 'react';
import { HeaderHelmet } from 'components/helmet/helmet';

const Templates = () => {
    return (
        <>
            <HeaderHelmet
                pageName="OpenResume Templates"
                ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
                ogTitle="OpenResume: The next generation resume"
                ogURL="https://openresume.info/templates"
            />
            <p>This is the templates page</p>
        </>

    )
}

export default Templates;
