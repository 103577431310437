import styled from "styled-components";

export const CreatePage = styled.div`
  .MuiButton-containedPrimary {
    background-color: #5444ca;
    text-transform: none;
  }

  .MuiButton-label {
    text-transform: none;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #5444ca;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: #5444ca;
  }

  .MuiButton-textSecondary {
    color: #5444ca;
  }

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }
`;

export const CreationSection = styled.section`
  max-width: 1000px;
  margin: 20px auto 50px;
`;

export const ContentWrapper = styled.div`
  min-height: calc(100vh - 460px);
`;