import React from 'react';
import Logo from 'components/logo/Logo';
import {Copyright, LogoWrapper, Navigation, StyledFooter, StyledLink} from "components/footer/Footer.styles";

const Footer = () => {
  return (
    <StyledFooter>
      <LogoWrapper>
        <Logo grayScale/>
      </LogoWrapper>
      <Navigation>
        <StyledLink to="/contact">Contact</StyledLink>
        <StyledLink to="/privacy">Privacy Policy</StyledLink>
      </Navigation>
      <Copyright>© {new Date().getFullYear()}</Copyright>
    </StyledFooter>
  );
}

export default Footer;
