import styled from "styled-components";

export const AppContainer = styled.div`
  min-height: 100vh;
  top: 0;
  overflow: auto;

  main {
    margin-top: 80px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    min-height: calc(100vh - 160px);
  }

  #rcc-confirm-button {
    color: #fff !important;
    background-color: #4052f2 !important;
    font-family: Helvetica,sans-serif !important;
    padding: 8px 14px !important;
    font-size: 16px !important;
    margin-left: 0 !important;
  }

  #rcc-decline-button {
    color: #fff !important;
    background-color: #f24040 !important;
    font-family: Helvetica,sans-serif !important;
    padding: 8px 14px !important;
    font-size: 16px !important;
  }
`;