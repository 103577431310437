import styled from "styled-components";

export const PeriodInput = styled.div`
  border: 1px solid #e6e6e6;
  padding: 10px 20px;
  margin-top: 40px;
`;

export const PeriodTitle = styled.div`
  margin-bottom: 35px;
`;