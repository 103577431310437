import styled from "styled-components";
import {Viewport} from "styles/viewport";

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    margin-top: 50px;

    @media screen and (max-width: ${Viewport.MEDIUM}) {
        grid-template-columns: 1fr;
    }
`;

export const Information = styled.div`
    position: relative;

    p {
        display: flex;
    }

    .MuiSvgIcon-root {
        margin-right: 10px;
    }

    .description {
        margin-bottom: 40px;
    }
`;

export const PresentationImage = styled.img`
    max-width: 100%;

    @media screen and (max-width: ${Viewport.MEDIUM}) {
        max-width: 400px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
`;

export const Visuals = styled.div`
    @media screen and (max-width: ${Viewport.MEDIUM}) {
        margin-top: 60px;
    }
`;

export const VerticalAlignWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    @media screen and (max-width: ${Viewport.MEDIUM}) {
        position: initial;
        top: initial;
        transform: initial;
    }
`;