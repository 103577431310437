import React from 'react';
import { HeaderHelmet } from 'components/helmet/helmet';
import { Email, Twitter } from '@material-ui/icons';
import ContactIcon from './contact.svg';
import {Content, Information, PresentationImage, VerticalAlignWrapper, Visuals} from "pages/contact/Contact.styles";

const Contact = () => {
    return (
        <>
            <HeaderHelmet
                pageName="Contact | OpenResume"
                ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
                ogTitle="OpenResume: The next generation resume"
                ogURL="https://openresume.info/contact"
            />
            <Content>
                <Information>
                    <VerticalAlignWrapper>
                        <h1>Get in contact</h1>
                        <p className="description">
                            If you have any questions about the site, how your data is processed, or any feedback, please
                            use one of the following contact options.
                        </p>
                        <p> <Twitter /> <a href="https://twitter.com/BreakItStruan">@BreakItStruan</a></p>
                        <p> <Email /> <a href="mailto:struan@openresume.info">struan@openresume.info</a></p>
                    </VerticalAlignWrapper>
                </Information>
                <Visuals>
                    <PresentationImage src={ContactIcon} alt="Contact Us Illustration"/>
                </Visuals>
            </Content>
        </>
    )
}

export default Contact;
