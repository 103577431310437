import React, { useContext } from 'react';

import { Step, StepLabel, Stepper } from '@material-ui/core';
import WizardButtons from 'components/wizard-buttons/WizardButtons';

import CreateResumeStep1 from './steps/step-1/CreateResumeStep1';
import CreateResumeStep2 from './steps/step-2/CreateResumeStep2';
import CreateResumeStep3 from './steps/step-3/CreateResumeStep3';
import CreateResumeStep4 from './steps/step-4/CreateResumeStep4';
import { FormDataContext } from './components/FormDataContext';
import { HeaderHelmet } from '../../components/helmet/helmet';
import {ContentWrapper, CreatePage, CreationSection} from "pages/create/Start.styles";

const Start = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const formDataContext = useContext(FormDataContext);

    const steps: string[] = [
        "Getting started",
        "Your details",
        "Pick a template",
        "Generate resume"
    ]

    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:  return <CreateResumeStep1 />;
            case 1:  return <CreateResumeStep2 />;
            case 2:  return <CreateResumeStep3 />;
            case 3:  return <CreateResumeStep4 setStep={setStep} />;
            default: return <p>Unknown step</p>;
        }
    }

    const setStep = (step: number) => {
        setActiveStep(step);
    }

    const nextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        formDataContext.setProgressionDisabled(true);
    };

    const previousStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <CreatePage>
            <HeaderHelmet
                pageName="Create | OpenResume"
                ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
                ogTitle="OpenResume: The next generation resume"
                ogURL="https://openresume.info/start"
            />
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <CreationSection>
                <ContentWrapper>
                    {getStepContent(activeStep)}
                </ContentWrapper>
                <WizardButtons activeStep={activeStep}
                               steps={steps.length}
                               nextStep={nextStep}
                               nextDisabled={formDataContext.progressionDisabled}
                               previousStep={previousStep}/>
            </CreationSection>
        </CreatePage>
    )
}

export default Start;
