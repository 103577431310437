import {Button, Icon} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  text-transform: none !important; // Override MUI
`;

export const CreateResumeButton = () => {
  return (
    <Link to="/start" className="start">
      <StyledButton color="primary"
              variant="contained"
              className="action-button"
              endIcon={<Icon>arrow_forward</Icon>}>
        Create your OpenResume
      </StyledButton>
    </Link>
  )
}