import styled from "styled-components";
import {Viewport} from "styles/viewport";

export const HowItWorksPage = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
    margin-bottom: 50px;

    @media screen and (max-width: ${Viewport.LARGE}) {
        grid-column-gap: 50px;
    }
    
    @media screen and (max-width: ${Viewport.MEDIUM}) {
        grid-template-columns: 1fr;
    }
`;

export const CallToActionHint = styled.p`
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: bold;
`;

export const ResumePreview = styled.div`
    margin-top: 70px;
    position: relative;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: ${Viewport.LARGE}) {
        margin-top: 30px;
    }

    @media screen and (max-width: ${Viewport.MEDIUM}) {
        margin-top: 70px;
    }
`;

export const ResumeImage = styled.img`
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 30px #00000055;
`;

export const CodeCorner = styled.img`
    max-width: 400px;
    width: 60%;
    max-height: 400px;
    position: absolute;
    bottom: 0;
    right: 0;
    filter: hue-rotate(8deg) contrast(120%) saturate(130%);
    border-bottom-right-radius: 10px;
`;
