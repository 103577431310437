import styled from "styled-components";
import {Viewport} from "styles/viewport";

export const SquareOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 300px;
  grid-column-gap: 20px;
  
  h2 {
    margin-top: 0;
  }

  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-template-rows: 220px;
    
    h2 {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: ${Viewport.MEDIUM}) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`;

export const SquareOptionButton = styled.div`
  border: 1px solid #CCC;
  padding: 30px;

  &:hover, &.active {
    outline: 4px solid #6C63FF;
    cursor: pointer;
  }

  .MuiIcon-root {
    display: block;
    font-size: 64px;
    color: #333;
  }

  @media screen and (max-width: ${Viewport.MEDIUM}) {
    width: 300px;
    margin: auto;
  }
`;

export const OptionIconWrapper = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: 63px;
  }

  @media screen and (max-width: ${Viewport.LARGE}) {
    height: 100px;
  }
`;

export const OptionTitle = styled.h2`
  text-align: center;
  font-size: 16px;
`;

export const OptionSubtitle = styled.p`
  text-align: center;
  margin-top: 0;
  color: gray;
`;