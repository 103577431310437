import React from 'react';
import Grid from '@material-ui/core/Grid';
import {PeriodInput, PeriodTitle} from "components/schema-form/period/PeriodFieldTemplate.styles";

const PeriodFieldTemplate = (props: any) => {
    return (
       <PeriodInput>
           {props.title && (
               <PeriodTitle>
                   <props.TitleField
                       title={props.title}
                       required={props.required}
                   />
               </PeriodTitle>
           )}
           {props.description && (
               <props.DescriptionField
                   description={props.description}
               />
           )}
           <Grid container={true} spacing={4}>
               {
                   <>
                       <Grid
                           item={true}
                           xs={12}
                           key={1}
                           style={{ marginBottom: '-10px' }}
                       >
                           {props.properties[0].content}
                       </Grid>
                       <Grid
                           item={true}
                           xs={12}
                           sm={6}
                           key={2}
                           style={{ marginBottom: '10px' }}
                       >
                           {props.properties[1].content}
                       </Grid>
                       <Grid
                           item={true}
                           xs={12}
                           sm={6}
                           key={3}
                           style={{ marginBottom: '10px' }}
                       >
                           {props.properties[2].content}
                       </Grid>
                   </>
               }
           </Grid>
       </PeriodInput>
    );
}

export default PeriodFieldTemplate;
