import styled from "styled-components";
import {Viewport} from "styles/viewport";

export const HomePageWrapper = styled.div`
  padding-bottom: 50px;
`;

export const SectionHeader = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
  font-size: ${36 / 16}rem;
`;

export const LandingContainer = styled.section`
  margin-bottom: 250px;
  margin-top: ${50 / 16}rem;

  display: grid;
  grid-template-columns: 1fr 1.3fr;
  grid-column-gap: 50px;

  h1 {
    text-align: center;
  }

  h2 {
    text-align: center;
    margin-top: -20px;
  }

  @media screen and (max-width: ${Viewport.LARGE}) {
    margin-top: 30px;
    grid-template-columns: 1fr;
    margin-bottom: 140px;
    grid-row-gap: 60px;

    .preview {
      margin-top: 100px;
    }

    h2 {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: ${Viewport.MEDIUM}) {
    grid-template-columns: 1fr;
    margin-bottom: 150px;

    .preview {
      margin-top: 150px;
    }
  }
`;

export const CTA = styled.div`
  position: relative;
`;

export const VerticalWrapper = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  
  h1 {
    font-size: ${48 / 16}rem;
    margin-bottom: ${40 / 16}rem;
  }
  
  h2 {
    margin-bottom: ${60 / 16}rem;
  }

  @media screen and (max-width: ${Viewport.LARGE}) {
    position: relative;
    width: 100%;
    transform: none;
    top: 0;

    h2 {
      margin-bottom: ${30 / 16}rem;
    }
  }
`;

export const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 20px auto 20px auto;
  grid-column-gap: 20px;
  padding-bottom: 2rem;

  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-template-columns: auto;
  }
`;

export const Stage = styled.div`
  border: 1px solid #ccc;
  box-shadow: 5px 5px 5px #cccccc6b;

  svg {
    max-height: 250px;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  h3 {
    text-align: center;
    margin-bottom: ${24 / 16}rem;
  }

  @media screen and (max-width: ${Viewport.LARGE}) {
    margin-bottom: 30px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: ${Viewport.SMALL}) {
    width: 100%;
  }
`;

export const Count = styled.span`
  margin-left: 16px;
  font-size: 48px;
  font-weight: 500;
  position: absolute;
  color: gray;
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${Viewport.LARGE}) {
    display: none;
  }
`;

export const StepsSection = styled.section``;

export const BenefitsSection = styled.section`
  margin-top: 120px;
`

export const Centered = styled.div`
  text-align: center;
`;