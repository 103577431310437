import React from 'react';
import { Icon } from '@material-ui/core';
import {
    AddButton,
    DeleteButton,
    StringArray,
    StringArrayItem,
    StringArrayTitle
} from "components/schema-form/stringArray/StringArrayTemplate.styles";

const StringArrayTemplate = (props: any) => {
    return (
        <StringArray>
            {props.title && (
                <StringArrayTitle>
                    <props.TitleField
                        title={props.title}
                        required={props.required}
                    />
                </StringArrayTitle>
            )}
            {props.description && (
                <div>
                    <p>{props.description}</p>
                </div>
            )}
            { props.items.map((element: any, index: number) => (
                <StringArrayItem
                     key={index}>
                    { element.children }
                    <DeleteButton onClick={element.onDropIndexClick(index)}>
                        <Icon>delete</Icon>
                    </DeleteButton>
                </StringArrayItem>
            ))}
            {props.canAdd && (
                <AddButton type="button"
                        color="secondary"
                        startIcon={<Icon>add</Icon>}
                        onClick={props.onAddClick}>
                    Add item
                </AddButton>
            )}
        </StringArray>
    );
};

export default StringArrayTemplate;
