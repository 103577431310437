import axios, {AxiosRequestConfig} from "axios";
import { logError } from "utils/log";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const getUrl = async (path: string) => {
  const endpoint = `${apiBaseUrl}${path}`;
  console.log(endpoint);
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      }
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to retrieve data from ${endpoint}`;
    logError(err);
    throw Error(message);
  }
}

export const postUrl = async (path: string, body: any) => {
  const endpoint = `${apiBaseUrl}${path}`;
  const data = JSON.stringify(body);
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to send data to ${endpoint}`;
    logError(err);
    throw Error(message);
  }
}

export const patchUrl = async (path: string, body: any) => {
  const endpoint = `${apiBaseUrl}${path}`;
  const data = JSON.stringify(body);
  try {
    const config: AxiosRequestConfig = {
      method: "PATCH",
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to send update data at ${endpoint}`;
    logError(err);
    throw Error(message);
  }
}

export const deleteUrl = async (path: string) => {
  const endpoint = `${apiBaseUrl}${path}`;
  try {
    const config: AxiosRequestConfig = {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json'
      }
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to delete data at ${endpoint}`;
    logError(err);
    throw Error(message);
  }
}

export const putUrl = async (path: string, body: any) => {
  const endpoint = `${apiBaseUrl}${path}`;
  const data = JSON.stringify(body);
  try {
    const config: AxiosRequestConfig = {
      method: "PUT",
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to replace data at ${endpoint}`;
    logError(err);
    throw Error(message);
  }
}