import * as React from 'react';
import { Helmet } from "react-helmet"

export interface HeaderHelmetProps {
    pageName: string;
    ogImage: string;
    ogTitle: string;
    ogURL: string;
}

export const HeaderHelmet = (props: HeaderHelmetProps) => {
    const { pageName, ogImage, ogTitle, ogURL } = props;

    return (
        <Helmet htmlAttributes={{lang: 'en'}}>
            <meta charSet="utf-8"/>
            <title>{pageName}</title>

            {/* Open Graph metadata - see https://ogp.me/ */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={ogURL} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:alt" content="Screenshot of the OpenResume site" />
            <meta property="og:image:secure_url" content={ogImage} />
            <meta property="og:description" content="The next generation resume which allows you to apply to jobs with one click. Create yours now!" />
            <meta property="og:site_name" content="OpenResume" />
            <meta property="og:locale" content="en_GB" />
        </Helmet>
    )
}
