import React from 'react';
import { HeaderHelmet } from '../../components/helmet/helmet';

const Tools = () => {
    return (
        <>
            <HeaderHelmet
                pageName="OpenResume Tools"
                ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
                ogTitle="OpenResume: The next generation resume"
                ogURL="https://openresume.info/tools"
            />
            <p>This is the tools page</p>
        </>
    )
}

export default Tools;
