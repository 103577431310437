import styled from "styled-components";
import {Colours} from "styles/colors";
import {Viewport} from "styles/viewport";
import {Link} from "react-router-dom";

export const StyledHeader = styled.header`
  background-color: ${Colours.SHADE};
  border-bottom: 1px solid #ccc;
  position: fixed;
  width: 100%;
  z-index: 100;

  display: grid;
  grid-template-areas:
    "logo navigation action";
  grid-template-columns: 150px auto 200px;

  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-template-areas:
            "logo menu-button"
            "menu menu";
    grid-template-columns: 150px auto;
  }
`;

export const StyledLogo = styled.div`
  grid-area: logo;
`;

export const StyledNav = styled.nav`
  grid-area: navigation;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: ${Viewport.LARGE}) {
    &.full-size {
      display: none;
    }
  }
`;

export const StyledLink = styled(Link)`
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;

  border-bottom: 3px solid transparent;

  &:hover {
    border-bottom: 3px solid #6C63FF;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${Viewport.LARGE}) {
    display: none;
  }
`;

export const MenuButtonWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-area: menu-button;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
  }
  
  button {
    width: 40px;
    height: 40px;

    .MuiButton-label {
      padding: 0;
      width: 30px;
    }
  }
`;

export const Menu = styled.div`
  @media screen and (max-width: ${Viewport.LARGE}) {
    grid-area: menu;
    width: 100%;
  }
`;

export const MenuNav = styled.div`
  flex-direction: column;
  background-color: ${Colours.SHADE};
  border-top: 1px solid #CCC;

  a {
    height: 40px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    width: 100%;
    text-align: center;
    padding: 10px;
    display: inline-block;

    &:hover {
      border-bottom: 3px solid #6C63FF;
    }
  }
`;