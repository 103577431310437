import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import AnonymisedCV from './AnonymisedCV.png';
import styled from "styled-components";
import {Viewport} from "styles/viewport";

export const FightingDiscriminationPage = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
    margin-bottom: ${50 / 16}rem;

    @media screen and (max-width: ${Viewport.MEDIUM}) {
        grid-template-columns: 1fr;
    }
`;

export const CallToActionHint = styled.p`
    margin-top: ${40 / 16}rem;
    margin-bottom: ${40 / 16}rem;
    font-weight: bold;
`;

export const ResumePreview  = styled.div`
    margin-top: 70px;
    position: relative;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: ${Viewport.LARGE}) {
        margin-top: 30px;
    }

    @media screen and (max-width: ${Viewport.MEDIUM}) {
        margin-top: 70px;
    }
`;

export const ResumeImage = styled.img`
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 30px #00000033
`;

const FightingDiscrimination = () => {
    return (
        <FightingDiscriminationPage>
            <div>
                <h2>Hiring is biased</h2>
                <p>
                    When I was creating this site, my partner asked me for advice on how to improve her resume.
                    She wasn't seeing much attention from recruiters, and when applying to jobs, she wasn't getting
                    many returned calls. We changed one thing on her resume - her name.
                </p>
                <p>
                    Her name is quite unusual, and definitely sounds foreign to the UK job market. When we changed her
                    name to its anglicised version "Catherine", we were surprised to see the activity from recruiters
                    triple. She also had far more success in job interviews. Why is this?
                </p>
                <h2>The challenge of unconscious bias</h2>
                <p>
                    Our small change demonstrated what is happening systematically across the hiring industry.
                    Something as simple as your name can have a great impact on your success in finding a job.
                </p>
                <p>
                    <a href="https://www.nber.org/papers/w9873">White names receive 50 percent more callbacks for interviews.</a>
                </p>
                <h2>How can we fight bias?</h2>
                <p>
                    Many forward thinking employer will remove personally identifying information from resumes before they
                    are read. This means that there is a fairer assessment of the individual's abilities and talents,
                    rather than who they are. This is a great first step, but unfortunately most companies don't do this.
                </p>
                <h2>How does OpenResume help?</h2>
                <p>
                    OpenResume contains your whole job application in a digital format, embedded in your PDF. When
                    receiving an OpenResume, employers can automatically regenerate it without your personal information,
                    meaning that you will have a fair assessment. This is the first step we can take together to tackle
                    discrimination.
                </p>
                <CallToActionHint>
                    Ready to get started?
                </CallToActionHint>
                <Link to="/start" className="start">
                    <Button color="primary"
                            variant="contained"
                            className="action-button"
                            endIcon={<Icon>arrow_forward</Icon>}>
                        Create your OpenResume
                    </Button>
                </Link>
            </div>
            <ResumePreview>
                <ResumePreview>
                    <ResumeImage src={AnonymisedCV} />
                </ResumePreview>

            </ResumePreview>
        </FightingDiscriminationPage>
    )
}

export default FightingDiscrimination;
