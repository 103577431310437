import React, { useContext, useState } from 'react';
import { Icon } from '@material-ui/core';
import LinkedInLogo from './LI-Logo.png';
import { FormDataContext } from '../../components/FormDataContext';
import UpdateUpload from './update/UpdateUpload';
import { SquareOption } from "components/square-option/SquareOption";
import {SquareOptions} from "components/square-option/SquareOption.styles";
import styled from "styled-components";

export const IntroText = styled.p`
  text-align: center;
  margin-top: ${-40 / 16}rem;
  margin-bottom: ${50 / 16}rem;
`;

const CreateResumeStep1 = () => {
    const [creationType, setCreationType] = useState('');
    const formDataContext = useContext(FormDataContext);

    const startNewFlow = () => {
        setCreationType("new");
        formDataContext.setProgressionDisabled(false);
    }

    const startExistingFlow = () => {
        setCreationType("update");
    }

    const startLinkedInFlow = () => {
        // setCreationType("linkedIn");
    }

    const checkIfInProgress = () => {
        const storedData = JSON.parse(localStorage.getItem('createFormData') as string);
        if (storedData && storedData.details) {
            return true;
        }
        return false;
    }

    return (
        <div className="starting-page">
            <h1>Let's { creationType === "update" ? "update" : "create" } your OpenResume</h1>
            { creationType !== "update" &&
                <div>
                    <IntroText>
                        After making a choice, click 'Next' below the options to continue
                    </IntroText>
                    <SquareOptions>
                        <SquareOption actionClass={`${creationType === "new" && "active"}`}
                                      isActive={creationType === "new"}
                                      icon={<Icon>add_circle_outline</Icon>}
                                      title={checkIfInProgress() ? "Continue your progress" : "Create new OpenResume"}
                                      action={startNewFlow}
                        />
                        <SquareOption actionClass={`${creationType === "update" && "active"}`}
                                      isActive={creationType === "update"}
                                      icon={<Icon>edit</Icon>}
                                      title="Edit your OpenResume"
                                      action={startExistingFlow}
                        />
                        <SquareOption actionClass={`${creationType === "linkedIn" && "active"}`}
                                      isActive={creationType === "linkedIn"}
                                      icon={<img src={LinkedInLogo} alt="LinkedIn logo" />}
                                      title="Create from LinkedIn profile"
                                      subtitle="Coming soon"
                                      action={startLinkedInFlow}
                        />
                    </SquareOptions>
                </div>
            }
            { creationType === "update" && <UpdateUpload /> }
        </div>
    );
}

export default CreateResumeStep1;
