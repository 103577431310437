import styled from "styled-components";
import {Button, IconButton} from "@material-ui/core";

export const StringArray = styled.div`
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
`;

export const StringArrayTitle = styled.div`
  margin-bottom: 20px;
`;

export const StringArrayItem = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

export const DeleteButton = styled(IconButton)`
  margin-left: 10px;
`;

export const AddButton = styled(Button)`
  color: #5444ca;
  float: right;
  margin-top: 10px;

  .MuiButton-startIcon {
    display: inherit;
    margin-left: -4px;
    margin-right: 0;
  }

  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 24px;
  }
`;