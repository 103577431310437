import React from 'react';
import {LogoContainer, LogoLink} from "components/logo/Logo.styles";

interface LogoProps {
  grayScale?: boolean;
}

const Logo = (props: LogoProps) => {
  const isGrayScale = props.grayScale;

  return (
    <LogoContainer>
      <LogoLink className={`open-resume-logo ${isGrayScale && "grayscale"}`} to="/">
        <div className="bar-left">
          <span>Open Resume</span>
        </div>
      </LogoLink>
    </LogoContainer>
  );
}

export default Logo;
