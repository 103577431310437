import styled from "styled-components";
import SyntaxHighlighter from "react-syntax-highlighter";

export const StyledResumeReader = styled.div`
  display: grid;
  grid-template-areas:
    "input   button"
    "preview output";
  grid-template-columns: 1fr 1fr;

  max-height: 100vh;
`;

export const UploadButton = styled.input`
  grid-area: input;
`;

export const PreviewCanvas = styled.canvas`
  grid-area: preview;
  max-height: calc(100vh - 100px);
`;

export const PreviewCode = styled(SyntaxHighlighter)`
  grid-area: output;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
`;

export const ButtonsWrapper = styled.div`
  grid-area: button;

  button {
    margin-right: 10px;
  }
`;