import React from 'react';
import Grid from '@material-ui/core/Grid';
import {PeriodLabel} from "components/schema-form/period/MonthYearTemplate.styles";

const MonthYearTemplate = (props: any) => {
    return (
        <>
            {props.title && (
                <PeriodLabel>
                    <props.TitleField
                        title={props.title}
                        required={props.required}
                    />
                </PeriodLabel>
            )}
            {props.description && (
                <props.DescriptionField
                    description={props.description}
                />
            )}
            <Grid container={true} spacing={2}>
                {
                    <>
                        <Grid
                            item={true}
                            sm={6}
                            xs={12}
                            key={1}
                        >
                            {props.properties[0].content}
                        </Grid>
                        <Grid
                            item={true}
                            sm={6}
                            xs={12}
                            key={2}
                            style={{ marginBottom: '10px' }}
                        >
                            {props.properties[1].content}
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}

export default MonthYearTemplate;
