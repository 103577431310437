import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './Routes';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import GA4React from 'ga-4-react';
import {GlobalStyles} from "styles/global";
import {AppContainer} from "App.styles";
import CookieConsent, {Cookies} from "react-cookie-consent";

const cookieConsentEnabled = false;

const handleAcceptCookie = () => {
  try {
    setTimeout(_ => {
      const ga4react = new GA4React("G-TH1R46ZKJE");
      ga4react.initialize();
    }, 4000);
  } catch (err) {
  }
}

const handleDeclineCookie = () => {
  //remove google analytics cookies
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
};

function App() {
  useEffect(() => {
    handleAcceptCookie();
  }, []);

  return (
    <Router>
      <AppContainer>
        <Header/>
        <main>
          <Routes/>
        </main>
        <Footer/>

        {cookieConsentEnabled &&
            <CookieConsent
                enableDeclineButton
                declineButtonText="Decline"
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
                location="bottom"
                buttonText="I understand"
                cookieName="google-analytics">
                We use cookies to better understand who is visiting our site.
            </CookieConsent>
        }
      </AppContainer>
      <GlobalStyles/>
    </Router>

  );
}

export default App;
