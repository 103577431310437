import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Icon} from '@material-ui/core';
import Logo from 'components/logo/Logo';
import {
  Action,
  Menu,
  MenuButtonWrapper,
  MenuNav,
  StyledHeader,
  StyledLink,
  StyledLogo,
  StyledNav
} from "components/header/Header.styles";

const Header = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <StyledHeader>
      <StyledLogo>
        <Logo/>
      </StyledLogo>
      <StyledNav className="full-size">
        <StyledLink to="/start">Create</StyledLink>
        <StyledLink to="/about">About</StyledLink>
        <StyledLink to="/faqs">FAQs</StyledLink>
      </StyledNav>
      <Action>
        <Link to="/start">
          <Button color="primary"
                  variant="outlined"
                  endIcon={<Icon>arrow_forward</Icon>}>
            Get Started
          </Button>
        </Link>
      </Action>
      <MenuButtonWrapper>
        <Button color="primary"
                onClick={(event) => {
                  setMenuOpen(!menuOpen)
                }}
                variant="outlined">
          <Icon>menu</Icon>
        </Button>
      </MenuButtonWrapper>
      {menuOpen && (
        <Menu>
          <MenuNav>
            <Link to="/start" onClick={() => {
              setMenuOpen(false)
            }}>Create</Link>
            <Link to="/about" onClick={() => {
              setMenuOpen(false)
            }}>About</Link>
            <Link to="/faqs" onClick={() => {
              setMenuOpen(false)
            }}>FAQs</Link>
          </MenuNav>
        </Menu>
      )}
    </StyledHeader>
  );
}

export default Header;
