import {createGlobalStyle} from 'styled-components';

/* ============================================================
 * CSS RESET
 * ============================================================ */

// Modified version of Josh W. Comeau's CSS Reset
const CSSReset = createGlobalStyle`
  // 1. Use a more-intuitive box-sizing model.
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  // 2. Remove margin - We're not using this

  // 3. Allow percentage-based heights in the application
  html, body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    width: 100%;
  }

  // Typographic tweaks!
  // 4. Add accessible line-height
  // 5. Improve text rendering
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }

  // 6. Improve media defaults
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  // 7. Remove built-in form typography styles
  input, button, textarea, select {
    font: inherit;
  }

  // 8. Avoid text overflows
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  // 9. Create a root stacking context
  #root, #__next {
    isolation: isolate;
  }

  // 10. Disable hot-reloading iframe from catching events
  iframe {
    pointer-events: none;
  }
`;


/* ============================================================
 * TYPOGRAPHY
 * ============================================================ */

const defaultREMSize = 16;

const fontSize = {
  h1: 32 / defaultREMSize,
  h2: 24 / defaultREMSize,
  h3: 24 / defaultREMSize,
  h4: 18 / defaultREMSize,
  h5: 16 / defaultREMSize
}

// Rem by default is 16px, hence using ${px / 16} = rem;
const Typography = createGlobalStyle`
  
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    margin-block-end: 0;
  }
  
  h1 {
    font-size: ${fontSize.h1}rem;
    margin-top: ${fontSize.h1 * 1.5}rem;
    font-weight: bold;
  }

  h2 {
    font-size ${fontSize.h2}rem;
    margin-top: ${fontSize.h2 * 1.5}rem;
    font-weight: bold;
  }
  
  h3 {
    font-size: ${fontSize.h3}rem;
    margin-top: ${fontSize.h3 * 1.5}rem;
    font-weight: normal;
  }
  
  h4 {
    font-size: ${fontSize.h4}rem;
    font-weight: bold;
  }
  
  h5 {
    font-size: ${fontSize.h5}rem;
    font-weight: bold;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: #333;

    &:hover {
      color: black;
    }
  }

  p a {
    text-decoration: underline;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
`;

/* ============================================================
 * Material Overrides
 * ============================================================ */

const MaterialOverrides = createGlobalStyle`
  .MuiButton-root {
    font-family: 'Montserrat', sans-serif;
  }
`;

export const GlobalStyles = () => (
  <>
    <CSSReset/>
    <Typography/>
    <MaterialOverrides />
  </>
)