import styled from "styled-components";
import {Viewport} from "styles/viewport";

export const TemplatesPage = styled.div`
  h1 {
    text-align: center;
    margin-bottom: 50px;
  }
`;

export const TemplatesGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;

  @media screen and (max-width: ${Viewport.MEDIUM}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${Viewport.SMALL}) {
    grid-template-columns: 1fr;
  }
`;

export const TemplatePreview = styled.div`
  border: 1px solid #CCC;

  &:hover, &.active {
    &:not(.incomplete) {
      outline: 4px solid #6C63FF;
      cursor: pointer;
    }
  }

  &.incomplete {
    display: none;
  }

  h2 {
    text-align: center;
    font-size: 16px;
  }

  img {
    background-color: #CCC;
    width: 100%;
    margin-bottom: -4px;
  }
`;

export const TemplateComingSoon = styled.p`
  text-align: center;
  margin-top: -10px;
`;