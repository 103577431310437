import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Icon } from '@material-ui/core';
import { FormDataContext } from '../../components/FormDataContext';
import * as pdfjs from "pdfjs-dist";
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/display/api';
import {
    ActionTitle,
    ButtonGroup,
    DownloadAction,
    DownloadWrapper,
    EditActions,
    ErrorHeading,
    ErrorIcon,
    GenerateResumeLink,
    GeneratingResumeMessage,
    GenerationPage,
    PreviewTitle,
    PreviewWrapper,
    ResumeLoaded
} from "pages/create/steps/step-4/CreateResumeStep4.styles";
import {createResume} from "services/resumeGeneration";
import {CreateResumeRequest} from "typings/resume";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface CreateResumeStep4Props {
    setStep: (step: number) => void;
}

const CreateResumeStep4 = (props: CreateResumeStep4Props) => {
    let { setStep } = props;

    const formDataContext = useContext(FormDataContext);
    const [downloadLink, setDownloadLink] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("All set, creating your OpenResume...");

    const loadingMessages = [
        'Uploading your details and choosing the template...',
        'Generating typography and setting the formatting...',
        'Generating PDF and attaching any web links...',
        'Larger OpenResumes (especially academic ones) can take a while, please wait...',
        'Still generating... we appreciate your patience!'
    ]

    useEffect(() => {
        let timeout: any;

        const input: CreateResumeRequest = {
            // @ts-ignore
            details: formDataContext.formData.details,
            // @ts-ignore
            templateID: `${formDataContext.formData.chosenTemplate.template}`,
            tenantID: "OpenResume"
        };

        createResume(input).then((url) => {
            console.log(url);
            setDownloadLink(getS3URL(url));
            fetchAndRenderResume(url);
        });

        const fetchAndRenderResume = (data: any) => {
            const url = getS3URL(data);
            retryDownload(url, 5, 5);
        }

        // Polls the S3 bucket repeatedly to see if the resume is ready to download. Eventually,
        // a better alternative to this would be to use a notification based download, or subscription.
        //
        // This polls repeatedly, and uses CALL_FACTOR_MILLIS to determine how long to wait. Each call will
        // be spaced out further and further apart.
        //
        // E.g 10000 factor: 1st call 10 secs, 2nd call 20 secs, 3rd call 30 secs
        const retryDownload = (url: string, callsLeft: number, repeat: number) => {
            const CALL_FACTOR_MILLIS = 15000;

            if (callsLeft === 0) {
                setHasErrored(true);
                return;
            }
            const numCallsSoFar = (repeat - callsLeft);
            setLoadingMessage(loadingMessages[numCallsSoFar]);
            const waitTime = (numCallsSoFar + 1) * CALL_FACTOR_MILLIS;
            timeout = setTimeout(() => {
                pdfjs.getDocument(url).promise.then((pdf: PDFDocumentProxy) => {
                    renderResume(pdf);
                }).catch(() => {
                    callsLeft = callsLeft - 1;
                    retryDownload(url, callsLeft, repeat);
                });
            }, waitTime);
        }

        return () => {
            clearTimeout(timeout);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderResume = (pdf: PDFDocumentProxy) => {
        pdf.getPage(1).then((page: PDFPageProxy) => {
            setIsLoading(false);
            const viewport = page.getViewport({scale: 1.5});
            const canvas = document.getElementById("preview") as HTMLCanvasElement;
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport
            };
            // @ts-ignore
            page.render(renderContext);
        });
    }

    const displayLink = (data: any) => {
        return (
            <>
                {/* TODO: Use AWS SDK with pre-signed URLS in order to access the files. The current setup */}
                {/* is way too insecure. */}
                { isLoading ? (
                    <GeneratingResumeMessage>
                        {hasErrored ? (
                            <>
                                <ErrorIcon>warning</ErrorIcon>
                                <ErrorHeading>Failed to load your resume</ErrorHeading>
                                <p>Please press 'Back', and then return to this page to try again.</p>
                            </>
                        ) : (
                            <>
                                <CircularProgress />
                                <p>{loadingMessage}</p>
                            </>
                        )}
                    </GeneratingResumeMessage>
                ) : (
                    <ResumeLoaded>
                        <div>
                            <PreviewTitle>Preview</PreviewTitle>
                            <PreviewWrapper href={data.createResume}
                               target="_blank"
                               rel="noreferrer">
                                <canvas style={{width: "100%"}} id="preview" />
                            </PreviewWrapper>
                        </div>
                        <div>
                            <DownloadWrapper>
                                <DownloadAction>
                                    <ActionTitle>Download your OpenResume</ActionTitle>
                                    <GenerateResumeLink href={data.createResume}
                                       target="_blank"
                                       rel="noreferrer">
                                        <Button color="primary"
                                                variant="contained"
                                                onClick={downloadFile}
                                                className="download-button"
                                                endIcon={<Icon>download</Icon>}>
                                            Download now
                                        </Button>
                                    </GenerateResumeLink>
                                </DownloadAction>
                                <EditActions>
                                    <ActionTitle>Make some changes</ActionTitle>
                                    <ButtonGroup>
                                        <Button variant="outlined" onClick={() => setStep(2)}>
                                            Change template
                                        </Button>
                                        <Button variant="outlined" onClick={() => setStep(1)}>
                                            Change details
                                        </Button>
                                    </ButtonGroup>
                                </EditActions>
                            </DownloadWrapper>
                        </div>
                    </ResumeLoaded>
                )}
            </>
        );
    }

    const getS3URL = (response: any) => {
        const key = response.data;
        const resumeBucket = process.env.REACT_APP_GENERATED_PDF_BUCKET;
        return `https://${resumeBucket}.s3.eu-west-2.amazonaws.com/${key}.pdf`
    }

    const downloadFile = () => {
        formDataContext.setProgressionDisabled(false);

        const anchor = document.createElement('a');
        anchor.href = downloadLink;
        anchor.target = '_blank';
        anchor.download = 'OpenResume.pdf';
        anchor.rel = 'noreferrer';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    return (
        <GenerationPage>
            { isLoading && <h1> Generating your OpenResume</h1> }
            { downloadLink && displayLink(downloadLink) }
        </GenerationPage>
    )
}

export default CreateResumeStep4;
