import React from 'react';
import Form from "@rjsf/material-ui";
import { Button } from '@material-ui/core';
import styled from "styled-components";

export interface SchemaFormProps {
    schema: object;
    uiSchema: object;
    onSubmit: (formData: any) => any;
    onChange: (formData: any) => any;
    formData: any;
}

const StyledSchemaForm = styled.div`
    .MuiInputLabel-root {
        text-transform: capitalize;
    }
`;

const ClearAllButton = styled(Button)`
    display: inline-block !important;
    margin-top: -36px !important;
    float: right;
`;

const SchemaForm = (props: SchemaFormProps) => {
    const [formData, setFormData] = React.useState(props.formData);

    const onUpdate = (event: any) => {
        setFormData(event);
        props.onChange(event);
    }

    const clearData = (event: any) => {
        setFormData(undefined);
        props.onChange(undefined);
        window.scrollTo(0, 0);
    }

    return (
        <StyledSchemaForm>
            <Form className="schema-form"
                  schema={props.schema}
                  uiSchema={props.uiSchema}
                  formData={formData}
                  onSubmit={() => props.onSubmit(formData)}
                  onChange={e => onUpdate(e.formData)}
            />
            <ClearAllButton
                    onClick={clearData}
                    variant="outlined">
                Clear all
            </ClearAllButton>
        </StyledSchemaForm>
    );
}

export default SchemaForm;
