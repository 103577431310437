import React from "react";
import {
    OptionIconWrapper,
    OptionSubtitle,
    OptionTitle,
    SquareOptionButton
} from "components/square-option/SquareOption.styles";

interface SquareOptionProps {
    actionClass: string;
    isActive: boolean;
    icon: React.ReactNode;
    title: string;
    subtitle?: string;
    action: () => void;
}

export const SquareOption = (props: SquareOptionProps) => {
    const {actionClass, isActive, icon, title, subtitle, action} = props;

    const wrapEventWithEnter = (keyEvent: KeyboardEvent, callback: () => void) => {
        if (keyEvent.key === 'Enter') {
            callback();
        }
    }

    return (
        <SquareOptionButton className={actionClass}
             tabIndex={0}
             role="button"
             aria-pressed={isActive}
             onKeyPress={(event: any) => wrapEventWithEnter(event, action)}
             onClick={action}>
            <OptionIconWrapper>
                {icon}
            </OptionIconWrapper>
            <div>
                <OptionTitle>{title}</OptionTitle>
                { subtitle && <OptionSubtitle>{subtitle}</OptionSubtitle>}
            </div>
        </SquareOptionButton>
    );
}